import React from 'react';
import styled, { keyframes } from 'styled-components';
import Text, { H1 } from 'components/atoms/Typography';
import { LinkButton } from 'components/atoms/Button/Button';
import { breakPoints, colors } from 'utils/variables';
import BgAnimated from 'images/header-shapes2.inline.svg';
import Container from 'components/atoms/Container/Container';
import Menu from 'components/organisms/Menu/Menu';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const StyledWrapper = styled.div`
  position: relative;
  z-index: 1;
  margin: 0 0 15rem 0;
  opacity: 0;
  animation: ${keyframes`
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    `} 1500ms ease forwards;

  @media (min-width: ${breakPoints.desktop}) {
    margin: 0;
    height: 61.5rem;
  }
`;

const StyledContent = styled.div`
  position: relative;
  margin-top: 4rem;

  @media (min-width: ${breakPoints.tablet}) {
    margin-top: 15rem;
  }

  @media (min-width: ${breakPoints.desktop}) {
    margin-top: 28rem;
  }

  h1 {
    margin-bottom: 45rem;

    @media (min-width: ${breakPoints.tablet}) {
      margin-bottom: 60rem;
    }

    @media (min-width: ${breakPoints.desktop}) {
      margin-bottom: 0;
    }
  }

  .text {
    margin: 1.6rem 0 2.9rem 0;
    font-size: 1.6rem;
    line-height: 2.5rem;
    text-align: center;

    @media (min-width: ${breakPoints.desktop}) {
      max-width: 47rem;
      text-align: left;
    }
  }
`;

const StyledImage = styled.div`
  position: absolute;
  padding: 0;
  margin: 0;
  height: 35rem;
  width: 88%;
  top: 11.5rem;
  transform: translateX(7.5%);

  @media (min-width: ${breakPoints.tablet}) {
    height: 50rem;
    top: 10rem;
  }

  @media (min-width: ${breakPoints.desktop}) {
    height: auto;
    width: 61rem;
    top: -10rem;
    transform: translateX(61.3rem);
  }
`;

const StyledAnimated = styled.svg`
  display: block;
  overflow: visible;
  max-height: unset;
  position: absolute;
  padding: 0;
  margin: 0;
  height: 38rem;
  width: 100%;
  top: 10rem;
  transform: translateX(-0.5rem);

  @media (min-width: ${breakPoints.tablet}) {
    height: 57rem;
    top: 8rem;
    transform: translateX(-1.5rem);
  }

  @media (min-width: ${breakPoints.desktop}) {
    height: auto;
    width: 70rem;
    top: -12.5rem;
    transform: translate(55rem, 0.5rem);
  }

  #shape1,
  #shape2,
  #shape3,
  #shape4,
  #shape5,
  #shape6 {
    @media (min-width: ${breakPoints.tablet}) {
      animation: ${keyframes`
        0%, 100% {
          transform: ${`translate(${Math.floor(Math.random() * 4) - 1.5}rem, ${Math.floor(Math.random() * 4) - 1.5}rem)`};
        }
        50% {
          transform: ${`translate(${Math.floor(Math.random() * 4) - 1.5}rem, ${Math.floor(Math.random() * 4) - 1.5}rem)`};
        }
      `} ease-in-out infinite;
    }
  }

  #shape1 {
    animation-duration: 5000ms;
  }

  #shape2 {
    animation-duration: 7000ms;
  }

  #shape3 {
    animation-duration: 6000ms;
  }

  #shape4 {
    animation-duration: 7000ms;
  }

  #shape5 {
    animation-duration: 5000ms;
  }

  #shape6 {
    margin-right: -300px;
    animation-duration: 6000ms;
  }
`;

const StyledButtons = styled.div`
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  width: 100%;

  @media (min-width: ${breakPoints.desktop}) {
    flex-direction: row;
  }

  button {
    width: 100%;

    @media (min-width: ${breakPoints.desktop}) {
      width: auto;
    }

    &:first-child {
      @media (min-width: ${breakPoints.desktop}) {
        margin: 0 3rem 0 0;
      }
    }

    &:last-child {
      margin: 2rem 0;

      @media (min-width: ${breakPoints.desktop}) {
        margin: 0;
      }
    }
  }
`;

const StyledReward = styled(Img)`
  position: absolute !important;
  left: 50%;
  top: 60px;
  width: 150px;
  transform: translateX(-50%);

  @media (min-width: ${breakPoints.desktop}) {
    top: -10rem;
    left: auto;
    right: -15rem;
  }
`;

const HomepageHeader = () => {
  const { BgImage, rewardImage } = useStaticQuery(graphql`
    query {
      BgImage: file(relativePath: { regex: "/header-homepage-image.png/" }) {
        childImageSharp {
          fluid(quality: 100, maxHeight: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      rewardImage: file(relativePath: { regex: "/orly-motoryzacji.png/" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Container>
      <Menu />
      <StyledWrapper>
        <StyledImage
          as={Img}
          fluid={BgImage.childImageSharp.fluid}
          alt="header"
          objectFit="contain"
          objectPosition="50% 50%"
          style={{ position: 'absolute', overflow: 'visible' }}
          imgStyle={{ objectFit: 'contain' }}
        />
        <StyledReward
          fluid={rewardImage.childImageSharp.fluid}
          alt="Orły Motoryzacji 2020"
          objectFit="contain"
          objectPosition="50% 50%"
          imgStyle={{ objectFit: 'contain' }}
        />
        <StyledAnimated as={BgAnimated} />
        <StyledContent>
          <H1 color={colors.color1}>
            Serwis <b>samochodowy</b>
          </H1>
          <Text
            html={
              'Przez wszystkie lata działalności, aż po chwilę obecną świadczymy usługi na najwyższym poziomie.<br/>Staramy się sprostać oczekiwaniom wszystkich Klientów, nawet tych najbardziej wymagających.<br/>Serdecznie zapraszamy do zapoznania się z naszą ofertą i skorzystania z naszych usług.'
            }
          />
          <StyledButtons>
            <LinkButton
              to="/kontakt"
              text="Umów wizytę"
              backgroundColor={colors.color4}
              borderColor={colors.color3}
              color={colors.color3}
              backgroundColorHover={colors.color3}
              borderColorHover={colors.color3}
              colorHover={colors.white}
            />
            <LinkButton to="/o-nas" text="Poznaj nas" />
          </StyledButtons>
        </StyledContent>
      </StyledWrapper>
    </Container>
  );
};

export default HomepageHeader;
