import React from 'react';
import Layout from 'templates/Layout/Layout';
import Container from 'components/atoms/Container/Container';
import Footer from 'components/organisms/Footer/Footer';
import ShortServices from 'components/organisms/ShortServices/ShortServices';
import WhyWe from 'components/organisms/WhyWe/WhyWe';
import ShortShop from 'components/organisms/ShortShop/ShortShop';
import HomepageHeader from 'components/organisms/HomepageHeader/HomepageHeader';
import OurPriorities from 'components/organisms/OurPriorities/OurPriorities';

const Page = () => (
  <Layout
    metaTitle="Kubeczek auto serwis - Mechanik samochodowy Rybnik"
    metaDescription="Kubeczek auto serwis zajmuje się serwisowaniem i naprawą samochodów. Kompleksowa naprawa samochodów Rybnik. Nasi mechanicy zadbają o Twoje auto."
  >
    <HomepageHeader />
    <Container>
      <ShortServices />
      <WhyWe />
      <OurPriorities />
      <ShortShop />
    </Container>
    <Footer />
  </Layout>
);

export default Page;
