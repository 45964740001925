import React from 'react';
import styled from 'styled-components';
import Text, { H2, H3 } from 'components/atoms/Typography';
import { ShapeLink } from 'components/atoms/Button/Button';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { colors, breakPoints } from 'utils/variables';

const StyledWrapper = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 12rem 0 12rem 0;

  @media (min-width: ${breakPoints.tablet}) {
    margin: 22rem 0 22rem 0;
  }

  @media (min-width: ${breakPoints.desktop}) {
    margin: 26rem 0 28.4rem 0;
  }
`;

const StyledImages = styled.div`
  display: flex;
  width: 100%;

  @media (min-width: ${breakPoints.desktop}) {
    width: auto;
  }

  > div {
    border-radius: 0.3rem;
  }

  .back {
    width: 30rem;
    height: auto;
    top: 12rem;
    right: calc(50% - 15rem);

    @media (min-width: ${breakPoints.tablet}) {
      width: 34rem;
      height: 28rem;
      top: 12rem;
      right: calc(50% - 30rem);
    }

    @media (min-width: ${breakPoints.desktop}) {
      width: 45rem;
      height: 45rem;
      top: -7.9rem;
      right: 0.4rem;
    }
  }

  .front {
    display: none;

    @media (min-width: ${breakPoints.tablet}) {
      display: block;
      width: 33rem;
      height: 24rem;
      top: 20rem;
      left: calc(50% - 30rem);
    }

    @media (min-width: ${breakPoints.desktop}) {
      width: 40rem;
      height: 28rem;
      top: 13.9rem;
      right: 21.8rem;
      left: unset;
    }
  }
`;

const StyledContent = styled.div`
  text-align: center;

  @media (min-width: ${breakPoints.desktop}) {
    text-align: left;
  }

  h3 {
    margin-bottom: 1.7rem;
  }
  h2 {
    max-height: 8rem;
    margin-bottom: 40rem;

    @media (min-width: ${breakPoints.desktop}) {
      margin-bottom: 5.6rem;
    }
  }
  .text {
    margin-bottom: 3.3rem;

    @media (min-width: ${breakPoints.desktop}) {
      width: 50rem;
    }
  }
`;

const WhyWe = () => {
  const { imageBack, imageFront } = useStaticQuery(graphql`
    query {
      imageBack: file(relativePath: { eq: "whyWe-Back.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxHeight: 450) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageFront: file(relativePath: { eq: "whyWe-Front.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxHeight: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <StyledWrapper>
      <StyledContent>
        <H3 color={colors.color3}>Dlaczego jesteśmy najlepszym wyborem?</H3>
        <H2
          dangerouslySetInnerHTML={{
            __html: 'Wieloletnie <b>doświadczenie</b>',
          }}
        />
        <Text html="Firma Auto-Części KUBECZEK powstała w latach 80. Założycielem był Karol Kubeczek, który od początku działalności zajmował się sprzedażą części do samochodu marki Mercedes. Wychodząc naprzeciw oczekiwaniom naszych klientów, począwszy od 2007 roku poszerzyliśmy działalność o AUTO-SERWIS. Obecnie kontynuujemy tradycję i zajmujemy się sprzedażą części oraz naprawą bieżącą samochodów. Jednocześnie dbamy o standard naszych usług i systematycznie unowocześniamy wyposażenie zakładu." />
        <ShapeLink to="/o-nas" text="Poznaj nasz serwis" />
      </StyledContent>
      <StyledImages>
        <Img alt="Dlaczego my - back" className="back" fluid={imageBack.childImageSharp.fluid} style={{ position: 'absolute' }} />
        <Img alt="Dlaczego my - front" className="front" fluid={imageFront.childImageSharp.fluid} style={{ position: 'absolute' }} />
      </StyledImages>
    </StyledWrapper>
  );
};

export default WhyWe;
